const EnumDB = {
    YATAK_ODASI : 1,
    YEMEK_ODASI: 2,
    KOLTUK_TAKIMI: 3

};


export default EnumDB;

