import React from "react";


const Institution  = () => {


    return (
        <div>
            SİTEMİZ YAPIM AŞAMASINDADIR. LÜTFEN DİĞER SAYFALARI KULLANINIZ.
        </div>
    );

}


export default Institution;